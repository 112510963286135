
import { Vue, Component, Prop } from 'vue-property-decorator'


@Component
export default class CustomTable extends Vue {
	@Prop()
	data!: any[]

	@Prop()
	columns!: any[]

	currentPage = 1
	itemsPerPage = 10

	search = ''

	pageOptions= [10, 25, 50, 100]

	tableHeight = 0

	assignTableHeight(){
		const contentHeight = (document.querySelector('.content-container__view') as HTMLElement).clientHeight
		this.tableHeight = Math.max(contentHeight - 180, 400)
	}

	mounted(){
		this.assignTableHeight()
	}
}
