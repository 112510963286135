
import { Vue, Component } from 'vue-property-decorator'
import { person_columns } from '@/modules/static'
import { PersonStore } from '@/store'
import CustomTable from '@/components/CustomTable.vue'
import { Person } from '@/modules/models'

@Component({
	components: {
		CustomTable
	}
})
export default class PersonList extends Vue {
	columns = person_columns

	get persons(): Person[]{
		return Object.values(PersonStore.persons)
	}

	created(){
		PersonStore.loadPersons()
	}
}
