
export const bgc_columns = [
	{
		title: 'National ID',
		slot: 'national_id',
	},
	{
		title: 'First Name',
		slot: 'first_name',
	},
	{
		title: 'Middle Name',
		slot: 'middle_name',
	},
	{
		title: 'Last Name',
		slot: 'last_name',
	},
	{
		title: 'Second Last Name',
		slot: 'second_last_name',
	},
	{
		title: 'Date of Birth',
		slot: 'dob',
	},
	{
		title: 'Status',
		slot: 'status',
	},
]

export const bgc_dom_statusMapping = {
	pending: {
		label: 'Pending',
		type: 'is-warning',
		icon: 'clock',
	},
	passed: {
		label: 'Passed',
		type: 'is-success',
		icon: 'check',
	},
	failed: {
		label: 'Failed',
		type: 'is-danger',
		icon: 'close',
	},
}

export const bgc_dom_estadoActual = [
	"Libertad bajo fianza",
	"Fugitivo",
	"Cumpliendo condena",
	"Libertad pena cumplida",
	"Deportado",
]

export const bgc_dom_tipoSentencia = [
	"Prisión preventiva",
	"Prisión regular",
	"Sin prisión",
]

export const bgc_dom_razones = [
	"Lista Comunes",
	"Robo",
	"Robo con Escalamiento",
	"Robo Asalariado",
	"Asalto",
	"Bancarrotas y estafas",
	"Estafa contra estado",
	"Abuso de confianza",
	"Abuso de confianza contra el Estado",
	"Falsificación de moneda",
	"Falsificacion de cheques",
	"Homicidio, asesinato, parricidio, infanticidio y envenenamiento",
	"Heridas y golpes voluntarios no calificados homicidios",
	"Trafico de Drogas",
	"Posecion de Drogas",
	"Violencia de genero",
	"Manutencion de menores",
	"Porte y tenencia de armas",
	"Homicidio Involuntario",
	"Crímenes excusable",
	"Delitos excusables",
	"Violacion sexual a mayor de edad",
	"Agresiones sexuales",
]

export const person_columns = [
	{
		title: 'National ID',
		slot: 'national_id',
	},
	{
		title: 'First Name',
		slot: 'first_name',
	},
	{
		title: 'Middle Name',
		slot: 'middle_name',
	},
	{
		title: 'Last Name',
		slot: 'last_name',
	},
	{
		title: 'Second Last Name',
		slot: 'second_last_name',
	},
	{
		title: 'Date of Birth',
		slot: 'dob',
	},
]

export const user_columns = [
	{
		title: 'Full Name',
		slot: 'full_name',
	},
	{
		title: 'Email/Username',
		slot: 'email',
	},
	{
		title: 'Company',
		slot: 'company'
	},
	{
		title: 'Is Super User',
		slot: 'is_superuser',
	},
	{
		title: 'Is Active',
		slot: 'is_active',
	},
	{
		title: 'Is Test',
		slot: 'is_test',
	},
	{
		title: 'Has Quota',
		slot: 'has_quota_limit',
	},
	{
		title: 'Person Quota',
		slot: 'person_quota',
	},
	{
		title: 'BGC Quota',
		slot: 'bgc_quota',
	}
]
